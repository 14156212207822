html,
body,
#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  cursor: none;
}

body {
  background-color: #d0d0d0;
}

.container {
  display: flex;
  height: 100%;
}

.artwork-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.controls-container {
  display: flex;
  width: 400px;
  background-color: #aaa;
}

.row {
  font-family: monospace;
  color: white;
  box-sizing: border-box;
  padding: 4px;
  width: 100%;
}

.tiles {
  display: flex;
}

.container {
  display: flex;
  height: 100%;
}

.artwork-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.controls-container {
  display: none;
  width: 400px;
  background-color: rgba(200, 200, 200, 0.8);
  position: fixed;
  right: 0;
  height: 100%;
  top: 0;
}
